import * as constants from "../config/constants";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Checkbox from "@material-ui/core/Checkbox";
import React, { Component } from "react";
import UserProfile from "../Models/UserProfile";
import Quiz from "./quiz";
import axios from "axios";
import Grid from "@material-ui/core/Grid";

import TimerIcon from "@material-ui/icons/Timer";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";

import DialogContent from "@material-ui/core/DialogContent";
import LinearScaleIcon from "@material-ui/icons/LinearScale";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import FilterListIcon from "@material-ui/icons/FilterList";
import CloseIcon from "@material-ui/icons/Close";
import { Row, Col } from "reactstrap";
import BounceLoader from "react-spinners/BounceLoader";
import PulseLoader from "react-spinners/PulseLoader";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import QuizQuestions from "./quizQuestions";
import AttemptWiseReport from "../report/attemptWiseReport";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Slide from "@material-ui/core/Slide";
import ReactGA from "react-ga";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";

const drawerWidth = "360px";
const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: window.innerHeight - 70,
    zIndex: 1,
    overflow: "hidden",
    position: "relative",
    display: "flex",
    width: "100%",
  },
  content: {
    overflow: "auto",
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
    marginBottom: 40,
  },
  nested: {
    paddingLeft: theme.spacing.unit * 4,
  },
  titleFlex: {
    flex: 1,
    fontSize: "1.3125rem",
    fontWeight: 500,
    lineHeight: "1.16667em",
    fontFamily: "'Aclonica', sans-serif !important",
  },
  drawerPaper: {
    position: "relative",
    overflow: "hidden",
    width: drawerWidth,
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  mobileDrawerPaper: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    minWidth: drawerWidth,
    paddingLeft: theme.spacing.unit * 2,
    paddingTop: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  toolbar: theme.mixins.toolbar,
  label: {
    fontSize: "0.875rem",
  },
  alertSnackbar: {
    background: "#FF9800",
    color: "#FFFBF5",
  },
});

function TabContainer({ children, dir }) {
  return (
    <Typography
      component="div"
      dir={dir}
      style={{
        padding: 24,
        textAlign: "left",
      }}
    >
      {children}
    </Typography>
  );
}

TabContainer.propTypes = {
  children: PropTypes.node.isRequired,
  dir: PropTypes.string.isRequired,
};

let url_string = window.location.href;
let url = new URL(url_string);
let selectedQuizId = url.searchParams.get("qu");

class ListQuiz extends Component {
  constructor() {
    super();

    this.violationTimerID = 0;
    this.alertCount = 0;

    this.state = {
      limit: 12,
      isLoading: true,
      selectedQuizId: null,
      webcamEnabled: false,
      newQuiz: {
        title: "",
        description: "",
        tags: [],
        subTopics: [],
        linear: "linear",
        mode: "untimed",
        numberOfQuestionsNeeded: 50,
        availableTopics: {},
      },
      listOfQuiz: [],
      quizIds: [],
      attemptCode: "",
      violationCount: 0,
      numberOfQuizAttempts: 0,
      showViolationDialog: false,
      listOfQuestions: [],
      displayQuestions: false,
      isNext: true,
      isPrev: false,
      activeStep: 0,
      completed: {},
      bookmarked: {},
      steps: [],
      mode: null,
      bookmarkedCount: 0,
      bookmarkedDialogOpen: false,
      createQuizDialog: false,
      deleteQuizDialog: false,
      showAnalysis: false,
      showResults: false,
      error: {},
      snackBar: false,
      snackBarMessage: "",
      isMobile: window.innerWidth <= 500,
      listOfTags: [],
      listOfCompanies: [],
      listOfTagsOriginal: [],
      redirectNextQuiz: "",
      listOfCompaniesOriginal: [],
      filter: {
        timed: false,
        untimed: false,
        linear: false,
        nonlinear: false,
        tags: [],
        company: [],
      },
      bottomFilter: 0,
      currentFilters: [],
      noTagsFound: true,
      noCompaniesFound: true,
      noQuizFound: false,
      mobileFilters: false,
      filterTabIndex: 0,
    };

    if (selectedQuizId) {
      console.log("selectedQuizId", selectedQuizId);
      this.state.selectedQuizId = selectedQuizId;
    }
    this.populateQuizCB = this.populateQuizCB.bind(this);
    this.deleteQuizCB = this.deleteQuizCB.bind(this);
    this.subTopicSelectedCB = this.subTopicSelectedCB.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.openCreateQuizDialog = this.openCreateQuizDialog.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.finishCallback = this.finishCallback.bind(this);
    this.handleSignIn = this.handleSignIn.bind(this);
    this.handleFilterQuiz = this.handleFilterQuiz.bind(this);
    this.handleSearchChange = this.handleSearchChange.bind(this);
    this.handleOpenMobileFilters = this.handleOpenMobileFilters.bind(this);
    this.handleFilterTabClick = this.handleFilterTabClick.bind(this);
    this.windowFocused = this.windowFocused.bind(this);
    this.windowBlured = this.windowBlured.bind(this);
    this.increaseLimit = this.increaseLimit.bind(this);

    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
    ReactGA.pageview("/quiz");
  }
  handleFilterTabClick = (event, value) => {
    this.setState({
      filterTabIndex: value,
    });
  };

  handleOpenMobileFilters() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.mobileFilters = !copiedStates.mobileFilters;
    copiedStates.bottomFilter = copiedStates.currentFilters.length > 0 ? 0 : 3;
    this.setState(copiedStates);
  }
  async finishCallback() {
    let copiedStates = Object.assign({}, this.state);
    let that = this;

    window.removeEventListener("focus", this.windowFocused);
    window.removeEventListener("blur", this.windowBlured);

    axios
      .put(constants.API_BASE_URL + "/attempts/submit", {
        userId: UserProfile.getUserProfile().id,
        attemptCode: this.state.attemptCode,
        submissionStatus: 1,
      })
      .then(function (response) {
        ReactGA.event({
          category: "Quiz",
          action: "Submitted",
        });
        copiedStates.displayQuestions = false;
        copiedStates.showInstructions = false;
        copiedStates.showAnalysis = true;
        selectedQuizId = null;
        that.setState(copiedStates);
      });
    try {
      await axios({
        method: "put",
        url: constants.API_BASE_URL + "/attempts/plagiarism",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          attemptCode: this.state.attemptCode,
          quizId: this.state.quizId,
          finished: true,
          intervalsOffScreen: this.state.violationCount,
        },
      });
    } catch (err) {
      alert("Some error ocurred while submitting");
    }
  }
  deleteQuizCB(quizId) {
    let copiedStates = Object.assign({}, this.state);
    let listOfQuiz = [];
    for (let i = 0; i < this.state.listOfQuiz.length; i++) {
      if (this.state.listOfQuiz[i].id !== quizId) {
        listOfQuiz.push(this.state.listOfQuiz[i]);
      }
    }
    copiedStates.listOfQuiz = listOfQuiz;
    this.setState(copiedStates);
  }
  handleClose() {
    this.setState({
      createQuizDialog: false,
    });
  }
  handleSearchChange(event) {
    let copiedStates = Object.assign({}, this.state);
    let listOfCompanies = [];
    let listOfTags = [];
    copiedStates.noCompaniesFound = false;
    copiedStates.noTagsFound = false;
    let regex;
    if (event.target.value !== "") {
      for (let i = 0; i < copiedStates.listOfTagsOriginal.length; i++) {
        regex = new RegExp(
          event.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
          "ig"
        );
        if (copiedStates.listOfTagsOriginal[i].search(regex) > -1) {
          listOfTags.push(copiedStates.listOfTagsOriginal[i]);
        }
      }
      for (let i = 0; i < copiedStates.listOfCompaniesOriginal.length; i++) {
        regex = new RegExp(
          event.target.value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&"),
          "ig"
        );
        if (copiedStates.listOfCompaniesOriginal[i].search(regex) > -1) {
          listOfCompanies.push(copiedStates.listOfCompaniesOriginal[i]);
        }
      }
    } else {
      listOfTags = Object.assign([], copiedStates.listOfTagsOriginal);
      listOfCompanies = Object.assign([], copiedStates.listOfCompaniesOriginal);
    }
    if (listOfTags.length === 0) copiedStates.noTagsFound = true;
    if (listOfCompanies.length === 0) copiedStates.noCompaniesFound = true;

    copiedStates.listOfTags = listOfTags;
    copiedStates.listOfCompanies = listOfCompanies;
    copiedStates.searchTerm = event.target.value;
    this.setState(copiedStates);
    ReactGA.event({
      category: "Quiz",
      action: "Searching",
    });
  }
  windowFocused() {
    let plagiarismData = {
      attemptCode: this.state.attemptCode,
      focus: true,
    };
    axios
      .put(constants.API_BASE_URL + "/attempts/plagiarism", plagiarismData)
      .then(function (response) {})
      .catch(function (error) {});
    let that = this;
    setTimeout(
      () => that.setState({ ...that.state, showViolationDialog: false }),
      2000
    );
    clearInterval(this.violationTimerID);
  }
  windowBlured() {
    let that = this;
    let plagiarismData = {
      attemptCode: this.state.attemptCode,
      blur: true,
    };
    axios
      .put(constants.API_BASE_URL + "/attempts/plagiarism", plagiarismData)
      .then(function (response) {})
      .catch(function (error) {});

    setTimeout(function () {
      that.setState((prevState) => {
        return {
          ...prevState,
          showViolationDialog: true,
        };
      });
    }, 2000);

    this.violationTimerID = setInterval(function () {
      that.setState((prevState) => {
        return {
          ...prevState,
          violationCount: prevState.violationCount + 1,
        };
      });
    }, 5000);
  }
  async populateQuizCB(response, quizId, attemptCode) {
    let that = this;
    let initQuizRes;
    try {
      initQuizRes = await axios({
        method: "post",
        url: constants.API_BASE_URL + "/attempts/plagiarism",
        headers: {
          "Content-Type": "application/json",
        },
        data: {
          attemptCode: attemptCode,
          quizId: quizId,
          userId: UserProfile.getUserProfile().id,
        },
      });
    } catch (err) {
      alert(
        "Some error ocurred while tracking the quiz. Kindly report this error. Code: ERR_START_TRACK"
      );
    }

    let copiedStates = Object.assign({}, this.state);
    copiedStates.numberOfQuizAttempts = response.data.numberOfAttempts;
    for (let i = this.state.listOfQuiz.length - 1; i >= 0; i--) {
      if (this.state.listOfQuiz[i].id === quizId) {
        let steps = Array.apply(null, {
          length: response.data.details.listOfQuestions.length,
        }).map(Number.call, Number);
        copiedStates.selectedQuizId = quizId;
        copiedStates.attemptCode = attemptCode;
        copiedStates.listOfQuestions = response.data.details.listOfQuestions;
        copiedStates.linear = this.state.listOfQuiz[i].linear;
        copiedStates.showAnalysis = this.state.listOfQuiz[i].showAnalysis;
        copiedStates.redirectNextQuiz =
          this.state.listOfQuiz[i].redirectNextQuiz;
        copiedStates.webcamEnabled = this.state.listOfQuiz[i].webcamEnabled;
        copiedStates.showResults = this.state.listOfQuiz[i].showResults;
        copiedStates.mode = this.state.listOfQuiz[i].mode || "untimed";
        copiedStates.types = this.state.listOfQuiz[i].types;
        copiedStates.shuffleOptions =
          typeof this.state.listOfQuiz[i].shuffleOptions === "undefined"
            ? true
            : this.state.listOfQuiz[i].shuffleOptions;
        this.state.listOfQuiz[i].mode === "timed" &&
          (copiedStates.timeout =
            initQuizRes.data.resumeTime >= 0
              ? initQuizRes.data.resumeTime
              : this.state.listOfQuiz[i].timeout);
        // copiedStates.timeout = 0;
        copiedStates.steps = steps;
        copiedStates.displayQuestions = true;

        window.addEventListener("focus", this.windowFocused);
        window.addEventListener("blur", this.windowBlured);
        this.setState(copiedStates);
        break;
      }
    }
  }
  componentDidMount() {
    let that = this;

    let copiedStates = Object.assign({}, this.state);
    axios
      .get(
        constants.API_BASE_URL +
          "/quizs/users/" +
          UserProfile.getUserProfile().id
      )
      .then(function (response) {
        let listOfQuiz = [];
        let listOfTags = [];
        let listOfCompanies = new Set();
        response.data.details.map((q) => {
          q.visible = true;
          if (q.isCompanySpecific === true) {
            listOfCompanies.add(q.company.toUpperCase().trim());
          }
          copiedStates.quizIds.push(q.id);
          listOfQuiz.push(q);
          return 0;
        });
        copiedStates.isLoading = false;
        copiedStates.listOfQuiz = listOfQuiz;
        copiedStates.listOfCompanies = Array.from(listOfCompanies);
        copiedStates.listOfTags = listOfTags;
        if (listOfCompanies.length === 0) {
          copiedStates.noCompaniesFound = true;
        } else {
          copiedStates.noCompaniesFound = false;
        }
        if (listOfQuiz.length === 0) copiedStates.noQuizFound = true;
        copiedStates.mobileFilters = true;
        copiedStates.listOfCompaniesOriginal = Array.from(listOfCompanies);
        copiedStates.listOfTagsOriginal = Object.assign([], listOfTags);
        that.setState(copiedStates);
      })
      .catch(function (error) {
        copiedStates.noTagsFound = true;
        copiedStates.noCompaniesFound = true;
        copiedStates.noQuizFound = true;
        that.setState(copiedStates);
      });
  }
  openCreateQuizDialog() {
    let that = this;
    let copiedStates = Object.assign({}, that.state);
    axios
      .get(constants.API_BASE_URL + "/questions/categories/all")
      .then(function (response) {
        copiedStates.newQuiz.availableTopics = response.data.details;
        that.setState(copiedStates);
      })
      .catch(function (error) {});
    copiedStates.newQuiz.createQuizDialog = true;
    that.setState(copiedStates);
  }
  subTopicSelectedCB(checkedSubTopics) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.newQuiz.subTopics = checkedSubTopics;
    this.setState(copiedStates);
  }
  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    let topic = e.target.value;
    if (e.target.name === "subTopics") {
      if (copiedStates.newQuiz.subTopics.indexOf(topic) > -1) {
        let index = copiedStates.newQuiz.subTopics.indexOf(topic);
        copiedStates.newQuiz.subTopics.splice(index, 1);
      } else {
        copiedStates.newQuiz[e.target.name].push(topic);
      }
    } else {
      copiedStates.newQuiz[e.target.name] = e.target.value;
    }
    this.setState(copiedStates);
  }
  handleFilterQuiz(what, value = false, navigation = null) {
    let copiedStates = Object.assign({}, this.state);
    if (navigation !== null) {
      if (copiedStates.bottomFilter === navigation) return;
      copiedStates.bottomFilter = navigation;
      copiedStates.listOfQuiz.map((quiz) => {
        quiz.visible = true;
        return 0;
      });
      for (let k in copiedStates.filter) {
        copiedStates.filter[k] = k === "tags" || k === "company" ? [] : false;
      }
      copiedStates.currentFilters = [];
    }
    if (what === "clear") {
      copiedStates.listOfQuiz.map((quiz) => {
        quiz.visible = true;
        return 0;
      });
      for (let k in copiedStates.filter) {
        copiedStates.filter[k] = k === "tags" || k === "company" ? [] : false;
      }
      copiedStates.noTagsFound = !(copiedStates.listOfTagsOriginal.length > 0);
      copiedStates.noCompaniesFound = !(
        copiedStates.listOfCompaniesOriginal.length > 0
      );
      copiedStates.currentFilters = [];
      copiedStates.bottomFilter = 3;
      copiedStates.listOfTags = Object.assign(
        [],
        copiedStates.listOfTagsOriginal
      );
      copiedStates.listOfCompanies = Object.assign(
        [],
        copiedStates.listOfCompaniesOriginal
      );
      copiedStates.searchTerm = "";
      this.setState(copiedStates);
      return;
    }
    let index = copiedStates.currentFilters.indexOf(what);
    if (index === -1) {
      copiedStates.currentFilters.push(what);
    } else {
      what !== "tags" &&
        what !== "company" &&
        copiedStates.currentFilters.splice(index, 1);
    }

    if (what === "company") {
      let tagIndex = copiedStates.filter.company.indexOf(value);
      if (tagIndex === -1) {
        copiedStates.filter.company.push(value);
      } else {
        copiedStates.filter.company.splice(tagIndex, 1);
      }
      if (copiedStates.filter.company.length === 0) {
        copiedStates.currentFilters.splice(index, 1);
      }
    }

    if (copiedStates.currentFilters.length === 0) {
      copiedStates.listOfQuiz.map((quiz) => {
        quiz.visible = true;
        return 0;
      });
      what !== "tags" &&
        what !== "company" &&
        (copiedStates.filter[what] = !copiedStates.filter[what]);
      this.setState(copiedStates);
      return;
    }

    copiedStates.listOfQuiz.map((quiz) => {
      quiz.visible = false;
      return 0;
    });

    copiedStates.currentFilters.map((what) => {
      if (what === "company") {
        if (copiedStates.filter.company.length > 0) {
          copiedStates.filter.company.map((t) => {
            copiedStates.listOfQuiz.map((quiz) => {
              if (quiz.company && quiz.company.toUpperCase().trim() === t) {
                quiz.visible = true;
              }
              return 0;
            });
            return 0;
          });
        } else {
          copiedStates.listOfCompanies.map((t) => {
            copiedStates.listOfQuiz.map((quiz) => {
              if (quiz.company && quiz.company.toUpperCase().trim() === t) {
                quiz.visible = true;
              }
              return 0;
            });
            return 0;
          });
        }
      }
      return 0;
    });

    if (what !== "tags" && what !== "company")
      copiedStates.filter[what] = !copiedStates.filter[what];
    ReactGA.event({
      category: "Quiz",
      action: "Filtering",
    });
    this.setState(copiedStates);
  }
  handleSignIn(ref, isValid) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.error[ref] = isValid ? 0 : 1;
    this.setState(copiedStates);
  }
  increaseLimit = () => {
    this.setState({
      limit: this.state.limit + 12,
    });
  };
  render() {
    let { classes } = this.props;
    let { quizIds, numberOfQuizAttempts } = this.state;
    let attemptWiseReport = "";
    let cols = [];
    let displayed = 1;
    let hidden = 0;
    if (this.state.showAnalysis === true) {
      attemptWiseReport = (
        <AttemptWiseReport
          attemptCode={this.state.attemptCode}
          showResults={this.state.showResults}
          numberOfQuizAttempts={numberOfQuizAttempts}
          redirectNextQuiz={this.state.redirectNextQuiz}
        />
      );
    }

    if (
      this.state.selectedQuizId !== null &&
      this.state.isLoading === false &&
      quizIds.indexOf(this.state.selectedQuizId) === -1
    ) {
      return (
        <Dialog
          open={true}
          fullScreen
          onClose={this.handleEducationalDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <AppBar
            id={"fixed-header"}
            position="fixed"
            className={classes.appBar}
          >
            <Toolbar>
              <Typography
                variant="h2"
                color="inherit"
                className={classes.titleFlex}
              >
                <span className="titleFirstName">
                  {constants.PROD_NAME.charAt(0)}
                </span>
                {constants.PROD_NAME.substring(1)}
              </Typography>
            </Toolbar>
          </AppBar>
          <DialogContent
            style={{
              textAlign: "center",
              fontSize: 24,
            }}
          >
            <DialogTitle
              style={{
                textAlign: "center",
                fontSize: 24,
              }}
            >
              NOT AVAILABLE
            </DialogTitle>
            <br />
            <br />
            <img
              src="General/stop-sign.png"
              alt="Not Available"
              style={{
                width: "128px",
              }}
            />
            <br />
            <br />
            This test is not available at the moment!
          </DialogContent>
        </Dialog>
      );
    }

    let bodyArr = [];
    if (this.state.displayQuestions === false) {
      cols = this.state.listOfQuiz.map((quiz, index) => {
        quiz.shuffleOptions =
          typeof quiz.shuffleOptions === "undefined"
            ? true
            : quiz.shuffleOptions;
        if (quiz.onlyEnrollment === true) {
          quiz.visible = false;
        } else if (displayed > this.state.limit) {
          hidden++;
          quiz.visible = false;
        } else {
          displayed++;
          quiz.visible = true;
        }
        return (
          quiz.id === selectedQuizId && (
            <Quiz
              showInstructions={quiz.id === selectedQuizId}
              visible={quiz.visible}
              unlisted={quiz.unlisted}
              shuffleQuestions={quiz.shuffleQuestions}
              deleteQuizCB={this.deleteQuizCB}
              userId={quiz.userId ? quiz.userId : []}
              isCompanySpecific={quiz.isCompanySpecific}
              company={quiz.company}
              price={quiz.price}
              isAttempted={quiz.isAttempted}
              attemptCode={quiz.previousAttemptCode}
              shuffleOptions={quiz.shuffleOptions}
              submissionStatus={quiz.submissionStatus}
              mode={quiz.mode}
              linear={quiz.linear}
              isBought={quiz.isBought}
              listOfQuestions={quiz.listOfQuestions}
              populateQuizCB={this.populateQuizCB}
              subTopic={quiz.subTopics}
              showAnalysis={this.state.showAnalysis}
              topic={quiz.topic}
              timeout={quiz.timeout}
              categories={quiz.categories}
              key={quiz.id}
              tags={quiz.tags}
              showResults={quiz.showResults}
              id={quiz.id}
              title={quiz.title}
              description={quiz.description}
            />
          )
        );
      });
      if (this.state.isMobile)
        cols.push(
          <div
            key="apti"
            style={{
              margin: 25,
              visibility: "hidden",
            }}
          >
            Aptimithra
          </div>
        );

      let colsToDisplay = cols;

      if (this.state.isMobile && cols.length === 1) {
        if (this.state.noQuizFound || this.state.currentFilters.length > 0) {
          colsToDisplay = (
            <Typography className="nodatafound">--no data found--</Typography>
          );
        } else {
          colsToDisplay = (
            <Typography className="circularProgress" component="div">
              <BounceLoader
                sizeUnit={"px"}
                size={50}
                style={{
                  margin: "auto",
                }}
                color={"#212121"}
                loading={true}
              />
            </Typography>
          );
        }
      } else if (this.state.isMobile === false && cols.length === 0) {
        if (this.state.noQuizFound || this.state.currentFilters.length > 0) {
          colsToDisplay = (
            <Typography className="nodatafound">--no data found--</Typography>
          );
        } else {
          colsToDisplay = (
            <Typography className="circularProgress" component="div">
              <BounceLoader
                sizeUnit={"px"}
                size={50}
                style={{
                  margin: "auto",
                }}
                color={"#212121"}
                loading={true}
              />
            </Typography>
          );
        }
      }

      let tagList = this.state.listOfTags.map((t) => {
        return (
          <FormControlLabel
            key={t}
            control={
              <Checkbox
                checked={this.state.filter.tags.indexOf(t) > -1}
                onChange={(event) => this.handleFilterQuiz("tags", t)}
              />
            }
            label={t}
          />
        );
      });
      let companyList = this.state.listOfCompanies.map((t) => {
        return (
          <FormControlLabel
            classes={{
              label: classes.label,
            }}
            key={t}
            control={
              <Checkbox
                checked={this.state.filter.company.indexOf(t) > -1}
                onChange={(event) => this.handleFilterQuiz("company", t)}
              />
            }
            label={t}
          />
        );
      });

      return (
        <div>
          <div className={classes.root}>
            {this.state.isMobile ? (
              <div>
                <Slide
                  style={{
                    width: window.width,
                    maxWidth: window.width,
                    minWidth: window.width,
                  }}
                  direction="right"
                  in={this.state.mobileFilters}
                  mountOnEnter
                  unmountOnExit
                >
                  <Drawer
                    variant="permanent"
                    anchor="left"
                    open={this.state.openTOC}
                    classes={{
                      paper: classes.mobileDrawerPaper,
                    }}
                  >
                    <Typography
                      component="div"
                      style={{
                        overflowY: "auto",
                        height: "80%",
                      }}
                    >
                      {this.state.noTagsFound && this.state.noCompaniesFound ? (
                        this.state.listOfQuiz.length !== 0 ? (
                          <Row
                            style={{
                              marginRight: "0",
                              marginLeft: "0",
                            }}
                          >
                            <Col
                              sm={12}
                              style={{
                                height: "100%",
                              }}
                            >
                              <Typography className="nodatafound">
                                --no data found--
                              </Typography>
                            </Col>
                          </Row>
                        ) : (
                          <Row
                            style={{
                              marginRight: "0",
                              marginLeft: "0",
                            }}
                          >
                            <Col
                              sm={12}
                              style={{
                                height: "90%",
                              }}
                            >
                              <Typography
                                className="circularProgress"
                                component="div"
                              >
                                <PulseLoader
                                  sizeUnit={"px"}
                                  size={15}
                                  style={{
                                    margin: "auto",
                                  }}
                                  color={"#212121"}
                                  loading={true}
                                />
                              </Typography>
                            </Col>
                          </Row>
                        )
                      ) : (
                        <div>
                          <AppBar position="static" color="default">
                            <Tabs
                              value={this.state.filterTabIndex}
                              onChange={this.handleFilterTabClick}
                              indicatorColor="primary"
                              textColor="primary"
                              centered
                            >
                              <Tab
                                className="quizFilterTabs"
                                label={<Typography>{"Companies"}</Typography>}
                              />
                            </Tabs>
                          </AppBar>
                          {this.state.filterTabIndex === 0 && (
                            <TabContainer dir="x">{companyList}</TabContainer>
                          )}
                        </div>
                      )}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={(event) => this.handleFilterQuiz("clear", event)}
                    >
                      Clear Filters
                    </Button>
                    <br />
                  </Drawer>
                </Slide>
                <BottomNavigation
                  style={{
                    position: "fixed",
                    width: "100%",
                    left: 0,
                    bottom: 0,
                    zIndex: 1001,
                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                  }}
                  value={
                    this.state.currentFilters.length === 0
                      ? this.state.bottomFilter
                      : 0
                  }
                  onChange={this.handleChange}
                  showLabels
                >
                  <BottomNavigationAction
                    onClick={(event) => this.handleOpenMobileFilters()}
                    label={
                      this.state.mobileFilters
                        ? this.state.currentFilters.length > 0
                          ? "Apply Filters"
                          : "Close Filters"
                        : "Filters"
                    }
                    icon={
                      this.state.mobileFilters ? (
                        this.state.currentFilters.length > 0 ? (
                          <DoneAllIcon />
                        ) : (
                          <CloseIcon />
                        )
                      ) : (
                        <FilterListIcon />
                      )
                    }
                  />
                  <BottomNavigationAction
                    hidden={this.state.mobileFilters}
                    onClick={(event) =>
                      this.handleFilterQuiz("timed", event, 1)
                    }
                    label="Timed"
                    icon={<TimerIcon />}
                  />
                  <BottomNavigationAction
                    hidden={this.state.mobileFilters}
                    onClick={(event) =>
                      this.handleFilterQuiz("linear", event, 2)
                    }
                    label="Linear"
                    icon={<LinearScaleIcon />}
                  />
                  <BottomNavigationAction
                    hidden={this.state.mobileFilters}
                    onClick={(event) =>
                      this.handleFilterQuiz("clear", event, 3)
                    }
                    label="All"
                    icon={<DoneAllIcon />}
                  />
                </BottomNavigation>
              </div>
            ) : (
              <Slide direction="right" in={true} mountOnEnter unmountOnExit>
                <Drawer
                  variant="permanent"
                  anchor="left"
                  open={this.state.openTOC}
                  classes={{
                    paper: classes.drawerPaper,
                  }}
                >
                  <Typography
                    component="div"
                    style={{
                      overflowY: "auto",
                      height: "90%",
                    }}
                  >
                    {this.state.noTagsFound && this.state.noCompaniesFound ? (
                      this.state.listOfQuiz.length !== 0 ? (
                        <Row
                          style={{
                            marginRight: "0",
                            marginLeft: "0",
                          }}
                        >
                          <Col
                            sm={12}
                            style={{
                              height: "100%",
                            }}
                          >
                            <Typography className="nodatafound">
                              --no data found--
                            </Typography>
                          </Col>
                        </Row>
                      ) : (
                        <Row
                          style={{
                            marginRight: "0",
                            marginLeft: "0",
                          }}
                        >
                          <Col
                            sm={12}
                            style={{
                              height: "100%",
                            }}
                          >
                            <Typography
                              className="circularProgress"
                              component="div"
                            >
                              <PulseLoader
                                sizeUnit={"px"}
                                size={15}
                                style={{
                                  margin: "auto",
                                }}
                                color={"#212121"}
                                loading={true}
                              />
                            </Typography>
                          </Col>
                        </Row>
                      )
                    ) : (
                      <div>
                        <AppBar position="static" color="default">
                          <Tabs
                            value={this.state.filterTabIndex}
                            onChange={this.handleFilterTabClick}
                            indicatorColor="primary"
                            textColor="primary"
                            centered
                          >
                            <Tab
                              className="quizFilterTabs"
                              label={<Typography>{"Companies"}</Typography>}
                            />
                          </Tabs>
                        </AppBar>
                        {this.state.filterTabIndex === 0 && (
                          <TabContainer dir="x">{companyList}</TabContainer>
                        )}
                      </div>
                    )}
                  </Typography>
                  <Button
                    variant="contained"
                    onClick={(event) => this.handleFilterQuiz("clear", event)}
                  >
                    Clear Filters
                  </Button>
                  <Divider />
                </Drawer>
              </Slide>
            )}

            {attemptWiseReport}

            <Grid
              id="listOfQuiz"
              container
              direction="row"
              spacing={4}
              alignItems="center"
              justify="center"
              className={classes.content}
              hidden={this.state.isMobile ? this.state.mobileFilters : false}
            >
              {colsToDisplay}
              {hidden > 0 && (
                <Grid container alignItems="center" justify="center">
                  <Grid item xs={12} md={12} lg={12} xl={12}>
                    <Button
                      color="secondary"
                      style={{
                        textAlign: "center",
                      }}
                      variant="outlined"
                      onClick={(event) => this.increaseLimit()}
                    >
                      view more
                    </Button>
                  </Grid>
                </Grid>
              )}
              <br />
              <br />
            </Grid>
          </div>
        </div>
      );
    }
    return (
      <div className="containerWithMargin">
        <Snackbar
          open={this.state.showViolationDialog}
          onClose={() =>
            this.setState({ ...this.state, showViolationDialog: false })
          }
        >
          <SnackbarContent
            message={
              <div>
                Warning! Leaving the test window will result in plagiarism.
                <br />
                Kindly do not switch tabs/windows while taking the test
              </div>
            }
            className={classes.alertSnackbar}
          />
        </Snackbar>
        <QuizQuestions
          attemptCode={this.state.attemptCode}
          linear={this.state.linear}
          types={this.state.types}
          finishCallback={this.finishCallback}
          mode={this.state.mode}
          shuffleOptions={this.state.shuffleOptions}
          showAnalysis={this.state.showAnalysis}
          webcamEnabled={this.state.webcamEnabled}
          timeout={this.state.mode === "timed" ? this.state.timeout : 0}
          selectedQuizId={this.state.selectedQuizId}
          listOfQuestions={this.state.listOfQuestions}
          steps={this.state.steps}
        />
      </div>
    );
  }
}

ListQuiz.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ListQuiz);
