import * as constants from "../config/constants";
import React, { Component } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import Chip from "@material-ui/core/Chip";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ImageIcon from "@material-ui/icons/Image";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import { withStyles } from "@material-ui/core/styles";
import CardMedia from "@material-ui/core/CardMedia";
import CardActions from "@material-ui/core/CardActions";
import UserProfile from "../Models/UserProfile";
import axios from "axios";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DescriptionIcon from "@material-ui/icons/Description";
import TimerIcon from "@material-ui/icons/Timer";
import HomeIcon from "@material-ui/icons/Home";
import ShuffleIcon from "@material-ui/icons/Shuffle";
import DeleteIcon from "@material-ui/icons/Delete";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Typography from "@material-ui/core/Typography";
import Tags from "../tag/tags";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import Grid from "@material-ui/core/Grid";
import { Form } from "react-bootstrap";
import SubTopicSearch from "./subTopicSearch";
import QuizInstructions from "./quizInstructions";
import Snackbar from "@material-ui/core/Snackbar";
import ReactGA from "react-ga";

let generateHash = (str) => {
  let colors = [
    "#ffcdd2",
    "#e1bee7",
    "#d1c4e9",
    "#bbdefb",
    "#b3e5fc",
    "#b2dfdb",
    "#c8e6c9",
    "#f0f4c3",
    "#fff9c4",
    "#ffe0b2",
    "#ffccbc",
    "#cfd8dc",
  ];
  str = str.trim();
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }
  let colorIndex = hash % colors.length;
  return colors[colorIndex];
};

let generateImage = (str) => {
  str = str.trim();
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash += str.charCodeAt(i);
  }
  let colorIndex = hash % 11;
  return "FlatIcons/circle_" + colorIndex + ".png";
};

let getCategoryColor = (category) => {
  let colors = {
    Programming: "#43a047",
    Quants: "#9c27b0",
    Reasoning: "#009688",
    Verbal: "#2196f3",
    Coding: "#3f51b5",
    Uncategorised: "#4caf50",
    SoftSkills: "#ffc107",
  };
  return colors[category];
};

const styles = (theme) => ({
  cardTitle: {
    fontSize: 15,
    fontWeight: 700,
    textTransform: "uppercase",
  },
  paper: {},
  chipLabel: {
    fontSize: 10,
    paddingLeft: 5,
    paddingRight: 5,
    color: "#535665",
  },
  primaryText: {
    fontSize: "0.875rem",
  },
  avatar: {
    color: "rgb(34, 34, 34)",
    border: "1px solid rgb(204, 204, 204)",
    boxShadow:
      "rgba(0, 0, 0, 0.2) 0px 1px 5px 0px, rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 3px 1px -2px",
  },
  smBtn: {
    padding: "7px 8px",
    minWidth: "64px",
    fontSize: "0.8125rem",
    minHeight: "32px",
  },
  iconRoot: {
    minWidth: 32,
  },
});

function goFullScreen() {
  let elem = document.getElementById("appMainArea");
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.mozRequestFullScreen) {
    elem.mozRequestFullScreen();
  } else if (elem.webkitRequestFullscreen) {
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    elem.msRequestFullscreen();
  }
}

function secondsToString(seconds) {
  let numyears = Math.floor(seconds / 31536000);
  let numdays = Math.floor((seconds % 31536000) / 86400);
  let numhours = Math.floor(((seconds % 31536000) % 86400) / 3600);
  let numminutes = Math.floor((((seconds % 31536000) % 86400) % 3600) / 60);
  let numseconds = (((seconds % 31536000) % 86400) % 3600) % 60;

  let output = "";
  if (numyears > 0) {
    output += numyears + " yrs ";
  }
  if (numdays > 0) {
    output += numdays + " days ";
  }
  if (numhours > 0) {
    if (numhours === 1) output += numhours + " hr ";
    else output += numhours + " hrs ";
  }
  if (numminutes > 0) {
    if (numminutes === 1) output += numminutes + " min ";
    else output += numminutes + " mins ";
  }
  if (numseconds > 0) {
    if (numseconds === 1) output += numseconds + " sec ";
    else output += numseconds + " secs ";
  }
  return output;
}

let url_string = window.location.href;
let url = new URL(url_string);
let selectedQuizId = url.searchParams.get("qu");

class Quiz extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      attempsMessage: "",
      attemptsDialog: false,
      attemptCode: "AttemptCode:" + Math.random(0, 1) * 999,
      submissionStatus: this.props.submissionStatus
        ? this.props.submissionStatus
        : 0,
      userId: this.props.userId,
      company: this.props.company ? this.props.company : "",
      isCompanySpecific: this.props.isCompanySpecific,
      title: this.props.title,
      showAnalysis: this.props.showAnalysis,
      description: this.props.description,
      showResults: this.props.showResults,
      categories: this.props.categories,
      timeout: this.props.timeout,
      topic: this.props.topic,
      subTopic: this.props.subTopic,
      tags: this.props.tags,
      listOfQuestions: this.props.listOfQuestions,
      shuffleOptions: this.props.shuffleOptions,
      shuffleQuestions: this.props.shuffleQuestions,
      populateQuizCB: this.props.populateQuizCB,
      deleteQuizCB: this.props.deleteQuizCB,
      isBought: this.props.isBought,
      unlisted: this.props.unlisted,
      price: this.props.price,
      mode: this.props.mode,
      linear: this.props.linear,
      isAttempted: this.props.isAttempted,
      gridView:
        typeof this.props.gridView !== "undefined" ? this.props.gridView : true,
      editQuizDialog: false,
      deleteQuizDialog: false,
      showInstructions:
        typeof this.props.showInstructions !== "undefined"
          ? this.props.showInstructions
          : false,
      visible:
        typeof this.props.visible !== "undefined" ? this.props.visible : true,
      isMobile: window.innerWidth <= 500,
    };

    if (this.state.submissionStatus === 0) {
      this.state.attemptCode = this.props.attemptCode;
    }
    this.state.newQuiz = Object.assign({}, this.state);
    this.state.newQuiz.subTopics = this.state.subTopic;
    if (this.state.newQuiz.linear) this.state.newQuiz.linear = "linear";
    else this.state.newQuiz.linear = "nonlinear";
    this.populateQuiz = this.populateQuiz.bind(this);
    this.deleteQuiz = this.deleteQuiz.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.toggleDeleteQuizDialog = this.toggleDeleteQuizDialog.bind(this);
    this.subTopicSelectedCB = this.subTopicSelectedCB.bind(this);
    this.instructionCloseCallback = this.instructionCloseCallback.bind(this);
    this.showInstructions = this.showInstructions.bind(this);
    this.closeSnackBar = this.closeSnackBar.bind(this);
    this.toggleAttemptsDialog = this.toggleAttemptsDialog.bind(this);
    ReactGA.initialize("UA-125256246-1", {
      gaOptions: {
        userId: UserProfile.getUserProfile().email,
      },
    });
  }
  closeSnackBar() {
    this.setState({ snackBar: false });
  }
  showInstructions() {
    ReactGA.event({
      category: "Quiz",
      action: "Starting a quiz",
    });
    this.setState({
      showInstructions: true,
    });
    window.history.pushState(
      { html: "Hello", pageTitle: "pageTitle" },
      "",
      constants.HOST_URL + "/quiz?qu=" + this.state.id
    );
  }
  toggleDeleteQuizDialog() {
    this.setState({
      deleteQuizDialog: !this.state.deleteQuizDialog,
    });
  }
  deleteQuiz() {
    var request = {
      userId: UserProfile.getUserProfile()._id,
      quizId: this.state.id,
    };
    var that = this;
    axios
      .delete(constants.API_BASE_URL + "/quizs/" + this.state.id, {
        data: request,
      })
      .then(function (response) {
        that.toggleDeleteQuizDialog();
        that.state.deleteQuizCB(that.state.id);
      });
  }
  componentWillReceiveProps(nextProps) {
    let copiedStates = Object.assign({}, this.state);

    console.log("nextProps.visible", nextProps.visible);

    copiedStates.visible = nextProps.visible;
    copiedStates.gridView = nextProps.gridView;
    copiedStates.linear = nextProps.linear;
    if (copiedStates.submissionStatus === 0) {
      copiedStates.attemptCode = nextProps.attemptCode;
    }
    copiedStates.newQuiz = Object.assign({}, nextProps);
    copiedStates.subTopics = nextProps.subTopic;
    copiedStates.showInstructions = nextProps.showInstructions;
    if (copiedStates.linear === true) copiedStates.newQuiz.linear = "linear";
    else copiedStates.newQuiz.linear = "nonlinear";
    this.setState(copiedStates);
  }
  handleInputChange(e) {
    let copiedStates = Object.assign({}, this.state);
    var topic = e.target.value;
    if (e.target.name === "subTopics") {
      if (copiedStates.newQuiz.subTopics.indexOf(topic) > -1) {
        var index = copiedStates.newQuiz.subTopics.indexOf(topic);
        copiedStates.newQuiz.subTopics.splice(index, 1);
      } else {
        copiedStates.newQuiz[e.target.name].push(topic);
      }
    } else {
      copiedStates.newQuiz[e.target.name] = e.target.value;
    }
    this.setState(copiedStates);
  }
  instructionCloseCallback(accepted) {
    this.populateQuiz(accepted);
  }
  toggleAttemptsDialog() {
    this.setState({
      attemptsDialog: false,
    });
  }
  populateQuiz(accepted) {
    let copiedStates = Object.assign({}, this.state);
    if (accepted) {
      this.setState(copiedStates);
      var that = this;
      axios
        .get(
          constants.API_BASE_URL +
            "/quizs/users/" +
            that.state.id +
            "/" +
            UserProfile.getUserProfile()._id +
            "/new"
        )
        .then(function (response) {
          copiedStates.showInstructions = false;
          that.setState(copiedStates);
          if (response.data.details !== -1) {
            goFullScreen();
            that.state.populateQuizCB(
              response,
              that.state.id,
              that.state.attemptCode
            );
          } else {
            that.setState({
              attemptsDialog: true,
              attempsMessage: response.data.message,
            });
          }
        })
        .catch(function (error) {});
    } else {
      copiedStates.showInstructions = false;
    }
    ReactGA.event({
      category: "Quiz",
      action: "Populating",
    });
    this.setState(copiedStates);
  }
  subTopicSelectedCB(checkedSubTopics) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.newQuiz.subTopics = checkedSubTopics;
    this.setState(copiedStates);
  }
  shouldComponentUpdate(nextProps, nextState) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.visible = nextState.visible;
    copiedStates.showInstructions = nextState.showInstructions;
    if (copiedStates.submissionStatus === 0) {
      copiedStates.attemptCode = nextState.attemptCode;
    }

    let copiedStatesProps = Object.assign({}, this.state);
    copiedStatesProps.visible = nextProps.visible;
    if (copiedStatesProps.submissionStatus === 0) {
      copiedStatesProps.attemptCode = nextProps.attemptCode;
    }
    copiedStatesProps.newQuiz = Object.assign({}, nextProps);
    copiedStatesProps.subTopics = nextProps.subTopic;
    if (copiedStatesProps.newQuiz.linear)
      copiedStatesProps.newQuiz.linear = "linear";
    else copiedStatesProps.newQuiz.linear = "nonlinear";
    return (
      JSON.stringify(copiedStatesProps) !== JSON.stringify(this.state) ||
      JSON.stringify(copiedStates) !== JSON.stringify(this.state)
    );
  }
  render() {
    let { classes } = this.props;

    let bodyArr = [];
    let cardStyle = {
      display: this.state.isMobile ? "inline-block" : "inline-flex",
      margin: 8,
    };
    if (this.state.isMobile) {
      cardStyle.width = "90%";
      cardStyle.margin = "5%";
    }

    let tempCategories = this.state.categories.slice(0, 3);

    return (
      <Grid
        item
        md={6}
        lg={6}
        xl={4}
        sm={12}
        xs={12}
        style={{
          display: this.state.visible ? "block" : "none",
        }}
      >
        <Dialog
          open={this.state.attemptsDialog}
          onClose={this.toggleAttemptsDialog}
        >
          <DialogTitle id="simple-dialog-title">
            Exceeded the number of attempts for this quiz
          </DialogTitle>
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              style={{
                color: "#000",
              }}
            >
              {this.state.attempsMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.toggleAttemptsDialog}
              color="primary"
              autoFocus
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.showInstructions && (
          <QuizInstructions
            instructionCloseCallback={this.instructionCloseCallback}
            quizStates={this.state}
          />
        )}

        <Card
          className={this.state.visible ? "quiz block" : "quiz none"}
          classes={{
            root: classes.paper,
          }}
        >
          <CardHeader
            classes={{
              title: classes.cardTitle,
            }}
            avatar={
              <Avatar
                aria-label="recipe"
                className={classes.avatar}
                style={{
                  background:
                    'url("' +
                    generateImage(this.state.title.toUpperCase()) +
                    '")',
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                }}
              >
                {this.state.title.toUpperCase().substring(0, 1)}
              </Avatar>
            }
            title={
              this.state.title.length > 25
                ? this.state.title.substring(0, 25).toUpperCase() + "..."
                : this.state.title.toUpperCase()
            }
            subheader={
              this.state.description.length > 40
                ? this.state.description.substring(0, 40) + "..."
                : this.state.description
            }
            style={{
              textAlign: "left",
              display: "flex",
              alignItems: "center",
              paddingTop: "16px",
              paddingLeft: "16px",
              paddingRight: "16px",
              paddingBottom: "16px",
            }}
          />
          <CardContent
            className="quizDescription"
            style={{
              padding: 0,
            }}
          >
            <Grid container spacing={24}>
              <Grid xs={4}>
                <List dense>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <img src="FlatIcons/faq.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.primaryText,
                      }}
                      primary={this.state.listOfQuestions.length}
                      secondary="Questions"
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={4}>
                <List dense>
                  <ListItem dense>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <img src="FlatIcons/clock.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.primaryText,
                      }}
                      classes={{
                        root: classes.primaryText,
                      }}
                      primary={secondsToString(this.state.timeout)}
                      secondary="Time"
                    />
                  </ListItem>
                </List>
              </Grid>
              <Grid xs={4}>
                <List dense>
                  <ListItem>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <img src="FlatIcons/company.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.primaryText,
                      }}
                      primary={
                        this.state.isCompanySpecific === true
                          ? this.state.company
                          : "No"
                      }
                      secondary="Company"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={24}
              style={{
                display: "none",
              }}
            >
              <Grid xs={6}>
                <List dense>
                  <ListItem dense>
                    <ListItemIcon classes={{ root: classes.iconRoot }}>
                      <img src="FlatIcons/shuffle.png" width="24" />
                    </ListItemIcon>
                    <ListItemText
                      classes={{
                        root: classes.primaryText,
                      }}
                      primary={this.state.linear ? "Yes" : "No"}
                      secondary="Adaptive"
                    />
                  </ListItem>
                </List>
              </Grid>
            </Grid>
          </CardContent>
          <Grid
            container
            style={{
              padding: "8px 12px",
            }}
          >
            <Grid
              xs={9}
              style={{
                textAlign: "left",
              }}
            >
              <Chip
                variant="outlined"
                style={{
                  border: "1px solid #ccc",
                  fontSize: 10,
                  borderRadius: 0,
                }}
                label={tempCategories.join(", ")}
                className="quizCategoryName"
                classes={{
                  label: classes.chipLabel,
                }}
              />
            </Grid>
            <Grid xs={3}>
              <div
                style={{
                  float: "right",
                }}
              >
                {typeof this.state.isBought === "undefined" ||
                this.state.isBought === false ? (
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={this.showInstructions}
                  >
                    {this.state.isAttempted
                      ? this.state.submissionStatus === 0
                        ? "Resume"
                        : "Retake"
                      : "Start"}
                  </Button>
                ) : (
                  <Button
                    size="small"
                    color="secondary"
                    variant="outlined"
                    onClick={this.showInstructions}
                  >
                    {this.state.isAttempted
                      ? this.state.submissionStatus === 0
                        ? "Resume"
                        : "Retake"
                      : "Start"}
                  </Button>
                )}
              </div>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    );
  }
}

export default withStyles(styles)(Quiz);
