import * as constants from "../config/constants";
import React, { Component } from "react";
import UserProfile from "../Models/UserProfile";
import Question from "./question";
import CodingQuestion from "./codingQuestion";
import QuizTimer from "./quizTimer";
import axios from "axios";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepButton from "@material-ui/core/StepButton";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import ForwardIcon from "@material-ui/icons/Forward";
import DoneAllIcon from "@material-ui/icons/DoneAll";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import Webcam from "react-webcam";

var startTime;
var endTime;

function scrollParentToChild(child, button) {
  let parent = child.parentElement.parentElement;
  let left = button === "next" ? child.offsetLeft + 24 : child.offsetLeft - 24;
  parent.scrollTo({
    left: left,
    top: child.offsetTop,
    behavior: "smooth",
  });
}

function exitFullScreen(close = false) {
  let elem = document.getElementById("appMainArea");
  if (elem.exitFullscreen) {
    elem.exitFullscreen();
  } else if (elem.mozCancelFullScreen) {
    elem.mozCancelFullScreen();
  } else if (elem.webkitExitFullscreen) {
    elem.webkitExitFullscreen();
  } else if (elem.msExitFullscreen) {
    elem.msExitFullscreen();
  }
}

class QuizQuestions extends Component {
  constructor(props) {
    super(props);

    const answeredQuestions = {};
    this.props.listOfQuestions.forEach((question, index) => {
      if (question.selectedOption) {
        answeredQuestions[index] = true;
      }
    });

    this.state = {
      selectedQuizId: this.props.selectedQuizId,
      showAnalysis: this.props.showAnalysis,
      attemptCode: this.props.attemptCode,
      listOfQuestions: this.props.listOfQuestions,
      linear: this.props.linear,
      types: this.props.types,
      webcamEnabled: this.props.webcamEnabled,
      // webcamEnabled: false,
      isNext: true,
      isPrev: false,
      activeStep: 0,
      completed: answeredQuestions,
      bookmarked: {},
      steps: this.props.steps,
      mode: this.props.mode,
      snackBar: false,
      bookmarkedCount: 0,
      bookmarkedDialogOpen: false,
      timeout: this.props.timeout,
      shuffleOptions:
        typeof this.props.shuffleOptions === "undefined"
          ? true
          : this.props.shuffleOptions,
      optionsReadOnly:
        typeof this.props.optionsReadOnly !== "undefined"
          ? this.props.optionsReadOnly
          : false,
      finishCallback: this.props.finishCallback,
      isMobile: window.innerWidth <= 500,
      answer: {
        result: "",
        explantion: "",
      },
      answerDialogOpen: false,
      enableSubmit:
        typeof this.props.enableSubmit === "undefined"
          ? true
          : this.props.enableSubmit,
      submitDailog: false,
      timerDialog: false,
      imageSrc: "",
    };
    this.completedSteps = this.completedSteps.bind(this);
    this.totalSteps = this.totalSteps.bind(this);
    this.isLastStep = this.isLastStep.bind(this);
    this.clearOptionCallback = this.clearOptionCallback.bind(this);
    this.allStepsCompleted = this.allStepsCompleted.bind(this);
    this.handleNext = this.handleNext.bind(this);
    this.handleBack = this.handleBack.bind(this);
    this.handleStep = this.handleStep.bind(this);
    this.handleOptionSelected = this.handleOptionSelected.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.getSteps = this.getSteps.bind(this);
    this.answeredQuestion = this.answeredQuestion.bind(this);
    this.handleBookMarkCallback = this.handleBookMarkCallback.bind(this);
    this.handleShowBookmarks = this.handleShowBookmarks.bind(this);
    this.bookmarkSelect = this.bookmarkSelect.bind(this);
    this.timerCompleted = this.timerCompleted.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleAnswerDialog = this.toggleAnswerDialog.bind(this);
    this.handleSubmitDialog = this.handleSubmitDialog.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.onTimerFinish = this.onTimerFinish.bind(this);
    this.setRef = this.setRef.bind(this);
    this.capture = this.capture.bind(this);
  }
  setRef = (webcam) => {
    console.log("imageSrc setRef", webcam);
    this.webcam = webcam;
  };
  capture = () => {
    let imgToSave = {
      img: this.webcam.getScreenshot(),
      attemptCode: this.state.attemptCode,
      userId: UserProfile.getUserProfile().id,
      quizId: this.state.selectedQuizId,
    };

    axios
      .post(constants.API_BASE_URL + "/attempts/capture", imgToSave)
      .then((response) => {
        console.log(response.data);
      });
  };
  handleSubmitDialog() {
    this.setState({
      submitDailog: true,
    });
  }

  handleSubmit() {
    exitFullScreen(true);
    this.state.finishCallback();
  }
  timerCompleted() {
    alert(
      "Time Up! We will be submitting the questions that you have answers till now"
    );
    this.handleSubmit();
  }
  bookmarkSelect(step) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.activeStep = step;
    copiedStates.bookmarkedDialogOpen = false;
    this.setState(copiedStates);
  }
  clearOptionCallback(step) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.listOfQuestions[step].selectedOption = [];
    this.setState(copiedStates);
  }
  handleBookMarkCallback(step, bookmarked) {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.listOfQuestions[step].isBookmarked = bookmarked;
    copiedStates.bookmarked[step] = bookmarked;
    if (bookmarked) {
      copiedStates.bookmarkedCount++;
    } else {
      copiedStates.bookmarkedCount--;
    }
    this.setState(copiedStates);
  }
  handleShowBookmarks() {
    this.setState({
      bookmarkedDialogOpen: !this.state.bookmarkedDialogOpen,
    });
  }
  answeredQuestion(questionState) {
    let copiedStates = Object.assign({}, this.state);
    questionState.quizId = this.state.selectedQuizId;
    if (this.state.types.indexOf("refresher") > -1) {
      copiedStates.optionsReadOnly = true;
    }
    endTime = new Date();
    let timeSpent = this.state.listOfQuestions[this.state.activeStep][
      "timeSpent"
    ]
      ? this.state.listOfQuestions[this.state.activeStep]["timeSpent"]
      : 0;
    copiedStates.listOfQuestions[this.state.activeStep]["timeSpent"] =
      timeSpent + (endTime - startTime);
    copiedStates.listOfQuestions[questionState["seqId"]].selectedOption =
      questionState.selectedOption;
    copiedStates.listOfQuestions[questionState["seqId"]].quizId =
      questionState.quizId;
    this.setState(copiedStates);
  }
  toggleAnswerDialog() {
    this.setState({
      answerDialogOpen: !this.state.answerDialogOpen,
    });
  }
  completedSteps() {
    return Object.keys(this.state.completed).length;
  }
  totalSteps() {
    return this.state.steps.length;
  }
  isLastStep() {
    return this.state.activeStep === this.totalSteps() - 1;
  }
  getSteps() {
    return this.state.steps;
  }
  allStepsCompleted() {
    return this.completedSteps() === this.totalSteps();
  }
  getStepContent(step) {
    startTime = new Date();
    let currentQuestion = this.state.listOfQuestions[step];
    if (currentQuestion.category === "Coding") {
      return (
        <CodingQuestion
          //key={Math.random()}
          randomId={Math.random()}
          quizId={this.state.selectedQuizId}
          questionId={currentQuestion.id}
          clearOptionCallback={this.clearOptionCallback}
          attemptCode={this.state.attemptCode}
          quizLinear={this.state.linear}
          isBookmarked={currentQuestion.isBookmarked}
          shuffleOptions={this.state.shuffleOptions}
          bookMarkCallback={this.handleBookMarkCallback}
          subTopics={currentQuestion.subTopics}
          numberOfCorrectAttempts={currentQuestion.numberOfCorrectAttempts}
          numberOfAttempts={currentQuestion.numberOfAttempts}
          optionsType={currentQuestion.optionsType}
          timeSpent={currentQuestion.timeSpent ? currentQuestion.timeSpent : 0}
          selectedOption={
            typeof currentQuestion.selectedOption !== "undefined"
              ? currentQuestion.selectedOption
              : []
          }
          seqId={step}
          key={currentQuestion.id}
          answerQuestionCB={this.answeredQuestion}
          tags={currentQuestion.tags}
          id={currentQuestion.id}
          title={currentQuestion.title}
          isCorrect={currentQuestion.isCorrect}
          sphereEngineQuestionId={currentQuestion.sphereEngineQuestionId}
          timeToSpend={Math.round(
            this.state.timeout / this.state.listOfQuestions.length
          )}
          handleOptionSelected={this.handleOptionSelected}
        />
      );
    }
    return (
      <Question
        clearOptionCallback={this.clearOptionCallback}
        quizLinear={this.state.linear}
        isBookmarked={currentQuestion.isBookmarked}
        shuffleOptions={this.state.shuffleOptions}
        bookMarkCallback={this.handleBookMarkCallback}
        subTopics={currentQuestion.subTopics}
        numberOfCorrectAttempts={currentQuestion.numberOfCorrectAttempts}
        numberOfAttempts={currentQuestion.numberOfAttempts}
        optionsType={currentQuestion.optionsType}
        timeSpent={currentQuestion.timeSpent ? currentQuestion.timeSpent : 0}
        selectedOption={
          typeof currentQuestion.selectedOption !== "undefined"
            ? currentQuestion.selectedOption
            : []
        }
        seqId={step}
        key={currentQuestion.id}
        answerQuestionCB={this.answeredQuestion}
        tags={currentQuestion.tags}
        id={currentQuestion.id}
        title={currentQuestion.title}
        description={currentQuestion.description}
        options={currentQuestion.options}
        correctOptions={currentQuestion.correctOptions}
        optionsReadOnly={this.state.optionsReadOnly}
        isCorrect={currentQuestion.isCorrect}
        timeToSpend={Math.round(
          this.state.timeout / this.state.listOfQuestions.length
        )}
        handleOptionSelected={this.handleOptionSelected}
      />
    );
  }
  async handleNext() {
    let copiedStates = Object.assign({}, this.state);
    let activeStep;
    endTime = new Date();
    let timeSpent = this.state.listOfQuestions[this.state.activeStep][
      "timeSpent"
    ]
      ? this.state.listOfQuestions[this.state.activeStep]["timeSpent"]
      : 0;
    copiedStates.listOfQuestions[this.state.activeStep]["timeSpent"] =
      timeSpent + (endTime - startTime);
    let prevQuestion = this.state.listOfQuestions[this.state.activeStep];

    let elmnt = document.getElementById(
      "question-step-" + this.state.activeStep
    );
    scrollParentToChild(elmnt, "next");

    if (prevQuestion.selectedOption) {
      let questionToSave = {
        questionId: prevQuestion.id,
        attemptCode: this.state.attemptCode,
        userId: UserProfile.getUserProfile().id,
        quizId: this.state.selectedQuizId,
        selectedAnswer: prevQuestion.selectedOption,
        timeSpent: prevQuestion.timeSpent,
      };
      axios
        .post(constants.API_BASE_URL + "/attempts", questionToSave)
        .then(function (response) {
          if (response.status === 200) {
            copiedStates.snackBarMessage = response.data.message;
          } else {
            copiedStates.snackBarMessage = "Error";
          }
        })
        .catch(function (error) {
          copiedStates.snackBar = true;
          copiedStates.snackBarMessage = "JS Error";
        });
    }

    if (this.isLastStep() && !this.allStepsCompleted()) {
      const steps = this.getSteps();
      activeStep = steps.findIndex((step, i) => !(i in this.state.completed));
    } else {
      activeStep = this.state.activeStep + 1;
    }
    copiedStates.activeStep = activeStep;
    this.setState(copiedStates);
    //this.handleComplete();
  }
  handleBack() {
    let copiedStates = Object.assign({}, this.state);
    let activeStep = this.state.activeStep;
    endTime = new Date();
    let timeSpent = this.state.listOfQuestions[this.state.activeStep][
      "timeSpent"
    ]
      ? this.state.listOfQuestions[this.state.activeStep]["timeSpent"]
      : 0;
    copiedStates.listOfQuestions[this.state.activeStep]["timeSpent"] =
      timeSpent + (endTime - startTime);
    copiedStates.activeStep = activeStep - 1;

    let elmnt = document.getElementById(
      "question-step-" + copiedStates.activeStep
    );
    scrollParentToChild(elmnt);

    this.setState(copiedStates);
  }
  handleStep = (step) => () => {
    let copiedStates = Object.assign({}, this.state);
    endTime = new Date();
    let timeSpent = this.state.listOfQuestions[this.state.activeStep][
      "timeSpent"
    ]
      ? this.state.listOfQuestions[this.state.activeStep]["timeSpent"]
      : 0;
    copiedStates.listOfQuestions[this.state.activeStep]["timeSpent"] =
      timeSpent + (endTime - startTime);
    let prevQuestion = this.state.listOfQuestions[this.state.activeStep];

    let elmnt = document.getElementById("question-step-" + step);
    scrollParentToChild(elmnt);

    if (prevQuestion.selectedOption) {
      let questionToSave = {
        questionId: prevQuestion.id,
        attemptCode: this.state.attemptCode,
        userId: UserProfile.getUserProfile().id,
        quizId: prevQuestion.quizId,
        selectedAnswer: prevQuestion.selectedOption,
        timeSpent: prevQuestion.timeSpent,
      };
      axios
        .post(constants.API_BASE_URL + "/attempts", questionToSave)
        .then(function (response) {
          if (response.status === 200) {
            copiedStates.snackBarMessage = response.data.message;
          } else {
            copiedStates.snackBarMessage = "Error";
          }
        })
        .catch(function (error) {
          copiedStates.snackBar = true;
          copiedStates.snackBarMessage = "JS Error";
        });
    }
    copiedStates.activeStep = step;
    copiedStates.optionsReadOnly = false;
    this.setState(copiedStates);
    //this.handleComplete();
  };
  componentDidMount() {
    let that = this;
    if (this.state.webcamEnabled === true) {
      setInterval(() => {
        that.capture();
      }, 5000);
    }
  }
  onTimerFinish() {
    let that = this;
    let copiedStates = Object.assign({}, this.state);
    copiedStates.timerDialog = true;
    setTimeout(function () {
      that.handleSubmit();
    }, 3000);
    this.setState(copiedStates);
  }
  handleOptionSelected() {
    const completed = this.state.completed;

    completed[this.state.activeStep] = true;
    this.setState({
      completed: completed,
    });

    this.setState({
      optionsReadOnly: false,
    });
  }
  handleReset() {
    this.setState({
      activeStep: 0,
      completed: {},
    });
  }
  handleClose() {
    this.setState({
      submitDailog: false,
    });
  }
  render() {
    const activeStep = this.state.activeStep;
    let that = this;

    const videoConstraints = {
      width: 1280,
      height: 720,
      facingMode: "user",
    };

    return (
      <div>
        {this.state.types.indexOf("refresher") > -1 ? (
          <Dialog
            open={this.state.answerDialogOpen}
            onClose={this.state.toggleAnswerDialog}
          >
            <DialogTitle id="simple-dialog-title">
              {this.state.answer.result}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {this.state.answer.explantion}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={this.toggleAnswerDialog}
                color="primary"
                autoFocus
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        ) : (
          ""
        )}

        <Dialog
          open={this.state.bookmarkedDialogOpen}
          onClose={this.handleShowBookmarks}
        >
          <DialogTitle id="simple-dialog-title">Your Bookmarks</DialogTitle>
          <div>
            <List>
              {this.state.listOfQuestions.map(function (question, index) {
                if (question.isBookmarked) {
                  return (
                    <ListItem button onClick={() => that.bookmarkSelect(index)}>
                      <ListItemText primary={"Go to question " + (index + 1)} />
                    </ListItem>
                  );
                }
                return true;
              })}
            </List>
          </div>
        </Dialog>
        <Grid container>
          <Grid
            xs={12}
            md={this.state.mode === "timed" ? 10 : 12}
            style={{
              border: "1px solid #888",
              borderRadius: 0,
            }}
          >
            <Stepper
              nonLinear={this.state.linear ? false : true}
              activeStep={this.state.activeStep}
              style={{ overflowX: "auto" }}
            >
              {this.state.steps.map((label, index) => {
                let className = "stepButton";
                if (this.state.bookmarked[index]) className += " bookmarked";
                {
                  /* if (
                  typeof this.state.listOfQuestions[index].selectedOption !==
                    "undefined" &&
                  this.state.listOfQuestions[index].selectedOption.length > 0
                )
                  className += " answered"; */
                }
                if (this.state.completed[index] === true)
                  className += " answered";
                return (
                  <Step key={label}>
                    <StepButton
                      onClick={this.handleStep(index)}
                      data-qnumber={index}
                      id={"question-step-" + index}
                      className={className}
                    />
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
          <Grid xs={12} md={this.state.mode === "timed" ? 2 : 0}>
            {this.state.mode === "timed" && (
              <QuizTimer
                time={this.state.timeout}
                onTimerFinish={this.onTimerFinish}
              />
            )}
          </Grid>
        </Grid>
        <div>
          <Typography component="div">
            {this.getStepContent(this.state.activeStep)}
          </Typography>

          {this.state.isMobile ? (
            <div>
              <br />
              <br />
              <BottomNavigation
                style={{
                  position: "fixed",
                  width: "100%",
                  left: 0,
                  bottom: 0,
                  borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                }}
                value={2}
                showLabels
              >
                {!this.state.linear && (
                  <BottomNavigationAction
                    disabled={this.state.bookmarkedCount === 0}
                    onClick={this.handleShowBookmarks}
                    label={
                      this.state.bookmarkedCount === 0
                        ? "Bookmarks"
                        : this.state.bookmarkedCount
                    }
                    icon={<BookmarkIcon />}
                  />
                )}
                {!this.state.linear && (
                  <BottomNavigationAction
                    disabled={this.state.activeStep === 0}
                    onClick={this.handleBack}
                    label="Prev"
                    icon={
                      <ForwardIcon
                        style={{
                          transform: "rotate(180deg)",
                        }}
                      />
                    }
                  />
                )}
                { /* this.completedSteps() === this.totalSteps() ? (
                  <BottomNavigationAction
                    onClick={this.handleSubmit}
                    icon={<DoneAllIcon />}
                    label="Submit"
                  />
                ) : */
                  (
                    <BottomNavigationAction
                      disabled={
                        this.state.activeStep ===
                        this.state.listOfQuestions.length - 1
                      }
                      onClick={this.handleNext}
                      icon={<ForwardIcon />}
                      label="Next"
                    />
                  )}
                <BottomNavigationAction
                  color="secondary"
                  label={"Finish"}
                  icon={<DoneAllIcon />}
                  hidden={!this.state.enableSubmit}
                  onClick={this.handleSubmitDialog}
                ></BottomNavigationAction>
              </BottomNavigation>
            </div>
          ) : (
            <div className="quizDesktopControls">
              <Button
                variant="contained"
                color="secondary"
                hidden={!this.state.enableSubmit}
                onClick={this.handleSubmitDialog}
                style={{
                  float: "right",
                }}
              >
                Finish Test
              </Button>
              {!this.state.linear && (
                <Button
                  style={{
                    float: "left",
                  }}
                  color="secondary"
                  disabled={this.state.bookmarkedCount === 0}
                  onClick={this.handleShowBookmarks}
                >
                  Show Bookmarks
                </Button>
              )}
              {!this.state.linear && (
                <Button
                  color="secondary"
                  disabled={this.state.activeStep === 0}
                  onClick={this.handleBack}
                >
                  Back
                </Button>
              )}
              {this.completedSteps() === this.totalSteps() ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  disabled={
                    this.state.activeStep ===
                    this.state.listOfQuestions.length - 1
                  }
                  variant="contained"
                  color="primary"
                  style={{
                    marginLeft: 10,
                  }}
                  onClick={this.handleNext}
                >
                  Next
                </Button>
              )}
            </div>
          )}
        </div>

        <Dialog
          open={this.state.timerDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"You ran out of time"}
          </DialogTitle>
          <DialogContent>
            Out of <strong>{this.state.listOfQuestions.length}</strong>{" "}
            questions, you have answered{" "}
            <strong>{this.completedSteps()}</strong> questions. You have book
            marked <strong>{this.state.bookmarkedCount}</strong> questions.
            <br />
            <br />
            Your test will be submitted automatically in another 3 seconds
          </DialogContent>
        </Dialog>

        <Dialog
          open={this.state.submitDailog}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"Do you want to submit the test?"}
          </DialogTitle>
          <DialogContent>
            Out of <strong>{this.state.listOfQuestions.length}</strong>{" "}
            questions, you have answered{" "}
            <strong>{this.completedSteps()}</strong> questions. You have book
            marked <strong>{this.state.bookmarkedCount}</strong> questions.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={this.handleClose}
              color="primary"
              autoFocus
              variant="outlined"
              size="small"
            >
              No
            </Button>
            <Button onClick={this.handleSubmit} size="small">
              Yes
            </Button>
          </DialogActions>
        </Dialog>

        {this.state.webcamEnabled === true && (
          <Webcam
            audio={false}
            className="webcamEnabledContainer"
            height={200}
            ref={this.setRef}
            screenshotFormat="image/jpeg"
            width={200}
            videoConstraints={videoConstraints}
          />
        )}
      </div>
    );
  }
}

export default QuizQuestions;
