import React, { Component } from "react";
import Avatar from "@material-ui/core/Avatar";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Badge from "@material-ui/core/Badge";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import PermIdentityIcon from "@material-ui/icons/PermIdentity";
import HourglassFullIcon from "@material-ui/icons/HourglassFull";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import DescriptionIcon from "@material-ui/icons/Description";
import TrendingUp from "@material-ui/icons/TrendingUp";
import StarIcon from "@material-ui/icons/Star";
import NotificationIcon from "@material-ui/icons/Notifications";
import AccountCircle from "@material-ui/icons/AccountCircle";
import Security from "@material-ui/icons/Security";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import NotificationList from "./notification/notificationList";
import ChangePassword from "./ChangePassword/changepassword";
import UserProfile from "./Models/UserProfile";
import { Link } from "react-router-dom";
import blue from "@material-ui/core/colors/blue";
import teal from "@material-ui/core/colors/teal";
import red from "@material-ui/core/colors/red";
import brown from "@material-ui/core/colors/brown";
import ShareDialog from "./social/shareDialog";
import InvitedFriends from "./friends/invitedFriends";
import Rate from "./rating/rate";
import * as constants from "./config/constants";

const linkStyle = {
  textDecoration: "none",
  color: "unset",
  border: "none",
  outline: "none",
};

const mailFolderListItems = (
  <div>
    <Link to="/" style={linkStyle}>
      <ListItem button>
        <ListItemIcon>
          <Avatar
            alt="Aptimithra"
            src="/logo.png"
            style={{
              width: 96,
              height: 96,
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={<Typography component="div">Aptimithra</Typography>}
        />
      </ListItem>
    </Link>

    <li>
      <Divider />
    </li>

    <Link to="/quiz" style={linkStyle}>
      <ListItem button>
        <ListItemIcon
          style={{
            color: brown[500],
          }}
        >
          <img src="/FlatIcons/home.png" width="24" />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
    </Link>

    <li>
      <Divider inset />
    </li>

    <Link to="/profile" style={linkStyle}>
      <ListItem button>
        <ListItemIcon
          style={{
            color: blue[500],
          }}
        >
          <img src="/FlatIcons/profile.png" width="24" />
        </ListItemIcon>
        <ListItemText primary="Profile " />
      </ListItem>
    </Link>

    <li>
      <Divider inset />
    </li>

    <Link to="/report" style={linkStyle}>
      <ListItem button>
        <ListItemIcon
          style={{
            color: red[500],
          }}
        >
          <img src="/FlatIcons/report.png" width="24" />
        </ListItemIcon>
        <ListItemText primary="Reports" />
      </ListItem>
    </Link>
  </div>
);

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  titleFlex: {
    flex: 1,
    fontSize: "1.3125rem",
    fontWeight: 500,
    lineHeight: "1.16667em",
    fontFamily: "'Aclonica', sans-serif !important",
  },
  flex: {
    flex: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
    color: "white",
  },
  menuButtonMobile: {
    marginLeft: -12,
    color: "white",
  },
  headerIcons: {
    color: "white",
    cursor: "pointer",
  },
  margin: {
    margin: theme.spacing(2),
  },
  colorSecondaryBadge: {
    backgroundColor: "red",
  },
});

const sideList = (
  <div>
    <List>{mailFolderListItems}</List>
  </div>
);

class Header extends Component {
  constructor() {
    super();
    let user = UserProfile.getUserProfile();
    this.state = {
      isOpen: false,
      isLogin: false,
      anchorEl: null,
      accountMenuOpen: false,
      open: false,
      anchorElNotification: null,
      showShareDialog: false,
      user: user,
      notificationCount: 0,
      dialogOpen: false,
      rateDialog: false,
      inviteCode: user ? user.inviteCode : "",
      isMobile: window.innerWidth <= 500,
    };
    this.handleToggleDrawer = this.handleToggleDrawer.bind(this);
    this.logoutProfile = this.logoutProfile.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleNotification = this.handleNotification.bind(this);
    this.updateNotificationCount = this.updateNotificationCount.bind(this);
    this.closeCallback = this.closeCallback.bind(this);
    this.handleInvite = this.handleInvite.bind(this);
    this.toggleShareDialogCB = this.toggleShareDialogCB.bind(this);
    this.displayFriendListDialog = this.displayFriendListDialog.bind(this);
    this.friendDialogCallback = this.friendDialogCallback.bind(this);
    this.handlePasswordDialogOpen = this.handlePasswordDialogOpen.bind(this);
    this.closeDialogCB = this.closeDialogCB.bind(this);
    this.handleRateDialog = this.handleRateDialog.bind(this);
    this.callBackClose = this.callBackClose.bind(this);
  }
  callBackClose() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.rateDialog = false;
    copiedStates.anchorEl = null;
    this.setState(copiedStates);
  }
  updateNotificationCount(count) {
    this.setState({
      notificationCount: count,
    });
  }
  handleClose() {
    this.setState({
      anchorEl: null,
    });
  }
  logoutProfile() {
    UserProfile.setUserProfile(null);
    this.setState({
      isLogin: false,
    });
    window.location = "signin";
  }
  handleToggleDrawer() {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  }
  handleAccountMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };
  handleNotification = (event) => {
    this.setState({ anchorElNotification: event.target });
  };
  closeCallback() {
    this.setState({
      anchorElNotification: null,
    });
  }
  handleInvite() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.showShareDialog = true;
    copiedStates.anchorEl = null;
    this.setState(copiedStates);
  }
  toggleShareDialogCB() {
    this.setState({
      showShareDialog: false,
    });
  }
  displayFriendListDialog() {
    this.setState({
      dialogOpen: true,
    });
    this.setState({
      anchorEl: null,
    });
  }
  handlePasswordDialogOpen() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.passwordDailog = true;
    copiedStates.anchorEl = null;
    this.setState(copiedStates);
  }
  closeDialogCB() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.passwordDailog = false;
    copiedStates.anchorEl = null;
    this.setState(copiedStates);
  }
  friendDialogCallback() {
    this.setState({
      dialogOpen: false,
    });
  }
  handleRateDialog() {
    let copiedStates = Object.assign({}, this.state);
    copiedStates.anchorEl = null;
    copiedStates.rateDialog = true;
    this.setState(copiedStates);
  }
  render() {
    const { classes } = this.props;
    const open = Boolean(this.state.anchorEl);
    let name = UserProfile.getUserProfile()
      ? UserProfile.getUserProfile().name
      : "";
    let shareMessage =
      name + " is inviting you. Join, improve & test your skills";
    const { isMobile } = this.state;
    return (
      <div>
        <AppBar id={"fixed-header"} position="fixed" className={classes.appBar}>
          <Toolbar>
            {this.state.user && this.state.user["isLoggedIn"] && (
              <IconButton
                onClick={this.handleToggleDrawer}
                className={
                  isMobile ? classes.menuButtonMobile : classes.menuButton
                }
                color="inherit"
                aria-label="Menu"
              >
                <MenuIcon />
              </IconButton>
            )}
            <Typography
              variant="h2"
              color="inherit"
              className={classes.titleFlex}
            >
              <span className="titleFirstName">
                {constants.PROD_NAME.charAt(0)}
              </span>
              {constants.PROD_NAME.substring(1)}
            </Typography>
            {this.state.user && this.state.user["isLoggedIn"] ? (
              <div>
                <IconButton
                  className={classes.headerIcons}
                  onClick={this.handleInvite}
                >
                  <GroupAddIcon />
                </IconButton>

                <Badge
                  className={classes.margin}
                  badgeContent={this.state.notificationCount}
                  classes={{
                    colorSecondary: classes.colorSecondaryBadge,
                  }}
                  color="secondary"
                  style={{
                    color: "red",
                  }}
                >
                  <NotificationIcon
                    onClick={this.handleNotification}
                    className={classes.headerIcons}
                  />
                </Badge>

                <IconButton
                  onClick={this.handleAccountMenu}
                  className={classes.headerIcons}
                >
                  <AccountCircle />
                </IconButton>
                <NotificationList
                  anchorElNotification={this.state.anchorElNotification}
                  countCallback={this.updateNotificationCount}
                  closeCallback={this.closeCallback}
                />
                <ChangePassword
                  passwordDailog={this.state.passwordDailog}
                  closeDialogCB={this.closeDialogCB}
                />
                <Menu
                  style={{
                    top: "30px",
                  }}
                  id="menu-appbar"
                  anchorEl={this.state.anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={open}
                  onClose={this.handleClose}
                >
                  <Link to="/profile" style={linkStyle}>
                    <MenuItem onClick={this.handleClose}>
                      <ListItemIcon>
                        <img src="/FlatIcons/profile.png" width="24" />
                      </ListItemIcon>
                      <ListItemText primary="Profile" />
                    </MenuItem>
                  </Link>

                  <MenuItem onClick={this.handlePasswordDialogOpen}>
                    <ListItemIcon>
                      <img src="/FlatIcons/protect.png" width="24" />
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </MenuItem>

                  <MenuItem onClick={this.handleRateDialog}>
                    <ListItemIcon>
                      <img src="/FlatIcons/favourite.png" width="24" />
                    </ListItemIcon>
                    <ListItemText primary="Rate Aptimithra" />
                  </MenuItem>

                  <MenuItem onClick={this.logoutProfile}>
                    <ListItemIcon>
                      <img src="/FlatIcons/exit.png" width="24" />
                    </ListItemIcon>
                    <ListItemText primary="Logout" />
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              ""
            )}
          </Toolbar>
        </AppBar>

        {this.state.user && (
          <Rate
            rateDialog={this.state.rateDialog}
            userOpen={true}
            page={"header"}
            callBackClose={this.callBackClose}
          />
        )}

        <ShareDialog
          showShareDialog={this.state.showShareDialog}
          shareUrl={constants.HOST_URL + "?code=" + this.state.inviteCode}
          shareMessage={shareMessage}
          toggleShareDialogCB={this.toggleShareDialogCB}
        />

        {this.state.user && this.state.user["isLoggedIn"] ? (
          <InvitedFriends
            dialogOpen={this.state.dialogOpen}
            friendDialogCallback={this.friendDialogCallback}
          />
        ) : (
          ""
        )}
        <Drawer open={this.state.isOpen} onClose={this.handleToggleDrawer}>
          <div tabIndex={0} role="button" onClick={this.handleToggleDrawer}>
            {sideList}
          </div>
        </Drawer>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Header);
